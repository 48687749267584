import React from 'react';
import pageStyle from '../../../assets/css/page.module.css';
import style from '../../../assets/css/main.module.css';
import Menu from '../../../components/menu';
import Footer from '../../../components/footer';
import HeroPage from '../../../components/hero.page';
import Hamburger from '../../../components/hamburger';
import SEO from '../../../components/seo';
import SimilarCard from '../../../components/similar.card';

import imgSEO from '../../../assets/img/services/pub.jpg';

import similar2 from '../../../assets/img/heroes/thumbnails/contenu.jpg';
import similar3 from '../../../assets/img/heroes/thumbnails/optimisation.jpg';
import similar4 from '../../../assets/img/heroes/thumbnails/social2.jpg';

import CtaSection from '../../../components/cta.section';
import cta from '../../../assets/img/illustrations/growth_analytics.svg';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Référencement payant SEA | Agence digitale | MDS Digital Agency"
      description="MDS se tient continuellement informé quant aux nouvelles pratiques de référencement naturel et établit pour vous une stratégie SEO sur mesure."
      keywords="Référencement payant"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2>Le référencement payant ou SEA</h2>

      <p>
        Les campagnes <strong>Adwords</strong> permettent de faire apparaître rapidement une annonce
        dans les premiers résultats de Google en fonction de mots clés prédéfinis.
      </p>
      <p>
        Les campagnes SEA fonctionnent sur le principe de coût par clic. Concrètement Google facture
        en fonction du nombre de personnes qui ont cliqué sur votre annonce. Ce système d’affichage
        d’annonces peut vite devenir onéreux et contre-productif si on s’y lance sans connaissance.
      </p>
      <p>
        Voici comment se déroule <strong>étape par étape</strong> la mise en place de{' '}
        <strong>campagnes de référencement payant</strong>:
      </p>
      <ul>
        <li>
          Vous nous faites part de <strong>vos objectifs</strong> et nous estimons ensemble{' '}
          <strong>le budget</strong> à investir en annonces publicitaires sur Google.
        </li>
        <li>
          Nous définissons ensemble <strong>les audiences</strong> auxquelles vous souhaitez vous
          adresser et <strong>quels messages</strong> vous voulez mettre en avant.
        </li>
        <li>
          Nous déterminons une liste de <strong>mots-clés</strong> pour lesquels vos annonces
          doivent être visibles.
        </li>
        <li>
          Nous établissons des <strong>objectifs chiffrés</strong>.
        </li>
        <li>
          Dès que vos annonces sont en ligne, notre équipe maintient les{' '}
          <strong>coûts d’acquisition au plus bas</strong> tout en optimisant{' '}
          <strong>la quantité et la qualité des visites</strong> sur votre site internet.
        </li>
        <li>
          Nous vous communiquons <strong>les résultats</strong> obtenus de manière régulière.
        </li>
        <li>
          Vous bénéficiez rapidement d’une <strong>plus grande visibilité</strong> sur le web et d’
          <strong>un nombre de visiteurs accru</strong>.
        </li>
      </ul>
      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="Quel est le budget idéal pour un SEA? Comment mesurer le retour sur investissement ?"
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard
            text="Gestion de contenu"
            url="/services/website/gestion-de-contenu/"
            img={similar2}
          />
          <SimilarCard
            text="Optimisation de site web"
            url="/services/website/optimisation/"
            img={similar3}
          />
          <SimilarCard text="Réseaux sociaux" url="/services/reseaux-sociaux/" img={similar4} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
